<template>
  <div class="wrap">
    <div class="The_header The_header_b">
      <ul class="tran_ul">
        <li class="left">
          <a><i class="iconfont icon-fanhui" @click="$router.back(-1)"></i></a>
        </li>
        <li></li>
      </ul>
    </div>
    <div class="main invit">
      <div class="invit_one">
        <!-- <StaticImage url="site_27.png" /> -->
        <div class="box" v-if="inviteCode">
          <ul>
            <h3>{{ t("common.title.inviteFriends") }}</h3>
            <li>
              <vue-qr :text="inviteCode.registerLink" :callback="test" qid="testid"></vue-qr>
            </li>
            <li>
              <span>{{ t("common.label.code") }}：</span>
              <span>{{ inviteCode.invitationCode }}</span>
            </li>
            <li>
              <input name="" type="text" v-model="inviteCode.registerLink" />
              <button
                id="btn"
                type="button"
                :data-clipboard-text="inviteCode.registerLink"
              >
                {{ t("common.button.copy") }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {
  ref,
  onMounted,
  onUnmounted,
  defineComponent
} from 'vue';
import ClipboardJS from 'clipboard/dist/clipboard';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {inviteCode} from '@/hooks/inviteCode'
// import StaticImage from '@/components/StaticImage.vue';
import { message } from 'ant-design-vue';
import { useI18n } from '@/lang';
export default defineComponent({
  components: {
    vueQr,
    // StaticImage,
  },
  setup() {
    const { t } = useI18n();
    let clipboard = ref(null);
    onMounted(() => {
      clipboard.value = new ClipboardJS('#btn');
      clipboard.value.on('success', function (e) {
        e.clearSelection();
        message.success({
          key: 'copy',
          content: t('common.clipboard.success'),
        });
      });
    });

    onUnmounted(() => {
      clipboard.value?.destroy();
    });
    return {
      inviteCode,
      clipboard,
      t
    };
  },
});
</script>
